export const SliderData = [

    {
        image:"Dibujos/perdida.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/SombraPadre.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/categorias.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/eco.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/asenso(1).jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/Foi.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/libertad(1).jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/Consciencia(1).jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/Devenir(1).jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/MaryTierra.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/Open.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/reflet.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/Chess.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/pia.jpg",
        alt:"large-vertical"
    },

];

