export const SliderData = [
    {
        image:"Dibujos/goustos.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/habitos.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/Fantome.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/Ineinander.jpg",
        alt:"large-vertical"
    },
    {
        image:"Dibujos/huerfanita.JPG",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/frijoles.JPG",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/cochecito.jpg",
        alt:"large-horizontal"
    },
    {
        image:"Dibujos/arbolito.jpg",
        alt: "large-vertical"
    },
    {
        image:"Dibujos/IMG_0255.jpg",
        alt:"large-vertical"
    }

];